// MainPage.js
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import '../../css/style.css';
import Header from '../Common/Header';
import Footer from '../Common/Footer';

const MainPage = () => {
  const [value, setValue] = useState(0);
  const TabPanel = ({ value, index, children }) => (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={6}>{children}</Box>}
    </div>
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      {/* Header */}
      {<Header/>}
      {/* Main Content */}

      <Box className="about_section">

        <Container>
        <Typography className="headingabout" variant="h4" component="h2" sx={{ marginTop: 2 }} style={{ fontFamily: " 'Poppins', sans-serif", fontWeight: "600" }}>
       Donation
            </Typography>
        </Container>
      </Box>
      {/* Footer */}
 <Box>
<Container>
    <div className="section_three paddmain">
      <Box>
        <Typography variant="h6" className="donation_content">
        Various Model Of Donation Available</Typography>
          <Typography variant="h6" className="donation_content">
          1. Donation Counter And Information Counter </Typography>
         <ul className="donation_content3">
          <li>Donation counters are available i Omkareshwar Temple premises . Donation counters accept Cash, Cheque, Demand Draft, Debit/Credit Card, Foreign Currency and kind donations.</li>
        <li>The Donation receipts for Cash and kind donations are given immediately and for other kind of donations</li>
         </ul>
         <Typography variant="h6" className="donation_content">
         2. Bank To Bank Transfer </Typography>
          <div className="bankdetail">




          <Grid container spacing={2}>
              <Grid item xs={3}>
                  <div className="tbodymain">
                  <Typography variant="h6" >
         Account Number</Typography>
                  </div>
              </Grid>
              <Grid item xs={3}>
              <div className="tbodymain">
                  <Typography variant="h6" >
         Bank Name</Typography>
                  </div>
              </Grid>
              <Grid item xs={3}>
              <div className="tbodymain">
                  <Typography variant="h6" >
         IFSC Code</Typography>
                  </div>
              </Grid>
              <Grid item xs={3}>
              <div className="tbodymain">
                  <Typography variant="h6" >
         Holder Name</Typography>
                  </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                  <div className="tbodymain2">
                  <Typography variant="h6" >
         00000000000</Typography>
                  </div>
              </Grid>
              <Grid item xs={3}>
              <div className="tbodymain2">
                  <Typography variant="h6" >
         State Bank of India</Typography>
                  </div>
              </Grid>
              <Grid item xs={3}>
              <div className="tbodymain2">
                  <Typography variant="h6" >
         STATE0250P</Typography>
                  </div>
              </Grid>
              <Grid item xs={3}>
              <div className="tbodymain2">
                  <Typography variant="h6" >
         Ram Giri Ji</Typography>
                  </div>
              </Grid>
            </Grid>
          </div>



          <div className="qrcode">
            <img src="/Donation/phonepay.jpeg" />
          </div>
         <Box>
        <Typography variant="h6" className="about_content">
            Vestibulum ultricies mattis ante. Nulla facilisi. Aliquam a aliquam magna. Nunc placerat sem at erat cursus bibendum. Curabitur nec mollis tellus, id rhoncus tellus.
            Cras semper odio eu vehicula tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed felis mi, volutpat at pellentesque eget, placerat in nunc.
            Pellentesque ut posuere quam. Sed id tempor nulla.

        </Typography>
          <Typography variant="h6" className="about_content">
              Integer eget dolor aliquet, porttitor ipsum et, malesuada urna. Donec mi arcu, fringilla sit amet luctus ut, sollicitudin semper diam.
              Vivamus volutpat congue mauris pretium iaculis. Vivamus dictum metus sit amet odio placerat, eget ullamcorper urna ornare.
              Mauris volutpat egestas lacus vel semper. Donec molestie metus eu nunc consequat, nec facilisis turpis interdum. Donec id tellus gravida, bibendum lacus eu, molestie justo.
              Interdum et malesuada fames ac ante ipsum primis in faucibus. Proin ut felis eu dolor gravida scelerisque vitae sit amet tortor. Fusce euismod justo at justo iaculis efficitur.
              Nullam scelerisque massa tincidunt hendrerit elementum. Quisque et rutrum dolor. Sed elementum volutpat nunc, a pretium purus efficitur quis.
              Sed imperdiet velit ut nisl tempor pharetra. Phasellus id tempus tortor. Nam interdum lorem nunc, ac iaculis tortor porta tincidunt.

          </Typography>
          <Typography variant="h6" className="about_content">
              Morbi congue placerat dictum. Morbi risus dui, vulputate eget neque facilisis, maximus euismod felis.
              Duis vel augue vitae ex venenatis iaculis. Nullam ac condimentum nunc. Quisque fringilla augue eget odio imperdiet gravida.
              Vivamus vitae varius nisi. In eget sapien metus. Maecenas id odio sit amet urna facilisis posuere. Nulla non nulla augue. Donec lobortis vestibulum arcu in semper.
              Praesent fringilla tincidunt elit, id vulputate sem hendrerit ultricies.
          </Typography>
        </Box>
      </Box>
      </div>
      </Container>
 </Box>
    {<Footer/>}
    </Box>
  );
};

export default MainPage;
