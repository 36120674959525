// MainPage.js
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Link from '@mui/material/Link';
import '../../css/style.css';
import Header from '../Common/Header';
import Footer from '../Common/Footer';


const About = () => {
  const [value, setValue] = useState(0);
  const TabPanel = ({ value, index, children }) => (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={6}>{children}</Box>}
    </div>
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <Box>
      {<Header />}
      <Box className="about_section">
        <Container>
          <Typography className="headingabout" variant="h4" component="h2" sx={{ marginTop: 2 }} style={{ fontFamily: " 'Poppins', sans-serif", fontWeight: "600" }}>
            You are only entitled to the action, never to its fruits
          </Typography>
        </Container>
      </Box>
      {/* Footer */}
      <Box>
        <Container>
          <div className="section_three">
            <Tabs
              className="tabsmain"
              value={value}
              onChange={handleChange}
              indicatorColor="orange" // Customize the active color here
            >
              <Tab label="Shree Ramgiri Baba" className="tabsheading" />
              <Tab label="Nangartas Sansthan " className="tabsheading" />
              <Tab label="Omkareshwar Sansthan" className="tabsheading" />
              <Tab label="Gurukul" className="tabsheading" />
              <Tab label="History" className="tabsheading" />
              <Tab label="Temple" className="tabsheading" />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Box>
                <Typography variant="h6" className="about_content">
                  Nunc eu sagittis felis, et pulvinar justo. Vivamus ut congue lectus. Ut luctus ac turpis at porta. Nullam elementum dolor quis tristique malesuada.
                  Interdum et malesuada fames ac ante ipsum primis in faucibus. Suspendisse ornare purus vitae leo accumsan, mattis lobortis neque sagittis. Morbi eget posuere enim.
                  Suspendisse molestie nisi at euismod tincidunt. Fusce mattis vestibulum sollicitudin. Vestibulum in nunc aliquam, tincidunt metus eget, sodales mauris. Duis in dapibus enim, vitae interdum risus.
                  Maecenas eget neque elit. Sed aliquam vel dui ut scelerisque.
                </Typography>
                <Typography variant="h6" className="about_content">
                  Curabitur semper, tortor eget dapibus lacinia, est metus imperdiet ante, ac vehicula ex sem a dui. Aenean pharetra euismod ligula.
                  Nunc sagittis ac neque nec ornare. Nunc at congue felis, quis euismod velit. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                  Nunc tempus lectus et mi posuere molestie. Donec dui diam, rhoncus ac risus nec, facilisis lacinia lacus. Sed erat arcu, aliquam ac leo sed, congue blandit velit.
                  Praesent dolor massa, commodo ut pharetra nec, hendrerit eu ante. Aenean laoreet nisi diam, non interdum augue tempor at. Curabitur luctus rhoncus quam id pellentesque.
                  Pellentesque maximus placerat tellus et lobortis. Fusce ut nibh eu urna tristique tristique. Donec ut lectus lacus.
                </Typography>
                <Box className="about_image">
                  <Container >
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <div>
                          <img src="/About/a1.png" className="img_about" />
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div>
                          <img src="/About/a2.png" className="img_about" />
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div>
                          <img src="/About/a3.png" className="img_about" />
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
                <Box>
                  <Typography variant="h6" className="about_content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas venenatis urna et lorem faucibus, eget porta felis lacinia. Nulla dapibus odio turpis, quis mollis justo mollis sit amet. Integer fermentum lorem in vulputate ullamcorper. Ut eu lorem sapien. Integer erat metus, ultrices ut nulla convallis, porttitor bibendum erat.
                    Curabitur iaculis quam enim, eget semper tortor vulputate porta. Vestibulum tempor sem sed massa hendrerit tempor.
                  </Typography>
                  <Typography variant="h6" className="about_content">
                    Aliquam odio massa, dignissim vitae blandit vitae, ultricies ac risus. Mauris laoreet venenatis dictum. Nam condimentum, ante et pharetra fringilla, tortor libero finibus massa, eget dapibus felis dui nec risus. Donec condimentum purus erat, sit amet pharetra leo pellentesque eu. Suspendisse at nisl quam. Duis ac pellentesque felis. Pellentesque varius fermentum dolor, quis aliquam sapien fringilla vel.
                    Integer interdum interdum nisi vel pulvinar. Fusce augue est, mattis at pulvinar id, vulputate convallis ante.
                    Curabitur consequat eros quam, sit amet aliquet dolor convallis sit amet. Etiam et arcu maximus, aliquet ligula eu, semper lacus. Fusce euismod lorem vel enim lacinia, non imperdiet quam ornare.
                    Fusce ullamcorper neque in dolor malesuada, a iaculis justo iaculis. Phasellus mauris erat, tincidunt eget lectus et, sollicitudin aliquam est.
                    Suspendisse lobortis vehicula nulla, pellentesque euismod felis dignissim ut. Phasellus blandit id dolor eget porttitor.
                  </Typography>
                  <Typography variant="h6" className="about_content">
                    Pellentesque consectetur sit amet justo eget commodo. Nulla a porttitor enim. Nullam quis semper erat, pellentesque aliquet felis. Cras nec erat nulla.
                    Donec id dui vel magna iaculis egestas. Curabitur vitae ipsum nisi. Mauris lacinia porta enim et commodo. Nullam semper iaculis pretium.
                  </Typography>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              Content for Tab 2
            </TabPanel>
            <TabPanel value={value} index={2}>
              Content for Tab 3
            </TabPanel>
            <TabPanel value={value} index={3}>
              Content for Tab 4
            </TabPanel>
            <TabPanel value={value} index={4}>
              Content for Tab 5
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Box>
                <Typography variant="h6" className="about_content">
                  Shree Nangartas Sansthan Temple
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Link href="/templedetail">
                    <div className="templeimages">
                      <img src="/About/ganesh.jpg" />
                      <div className="contenttemple">
                      <Typography variant="h6" className=" ">
                        Shree Ramgiriji Baba Mandir
                      </Typography>
                      <Typography variant="p" className=" ">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </Typography>
                      </div>
                    </div>
                    </Link>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className="templeimages">
                  <img src="/About/ganesh.jpg" />  <div className="contenttemple">
                      <Typography variant="h6" className=" ">
                        Mahadev Mandir        </Typography>
                        <Typography variant="p" className=" ">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </Typography>
                      </div>
                    </div>


                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className="templeimages">
                   <img src="/About/ganesh.jpg" /> <div className="contenttemple">
                      <Typography variant="h6" className=" ">
                        Dattatray Mandir        </Typography>
                        <Typography variant="p" className=" ">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </Typography>
                      </div>
                    </div>


                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className="templeimages">
                  <img src="/About/ganesh.jpg" />  <div className="contenttemple">
                      <Typography variant="h6" className=" ">
                        Shree Shewagiriji Baba            Mandir        </Typography>
                        <Typography variant="p" className=" ">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </Typography>
                      </div>
                    </div>


                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className="templeimages">

                      <img src="/About/ganesh.jpg" /> <div className="contenttemple">
                      <Typography variant="h6" className=" ">
                        Hanuman Mandir        </Typography>
                        <Typography variant="p" className=" ">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </Typography>
                      </div></div>


                  </Grid>
                </Grid>
              </Box>


              <Box className="temple_two">
                <Typography variant="h6" className=" 2">
                  Shree Omkareshwar Santhan Temple        </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <div className="templeimages">

                      <img src="/About/ganesh.jpg" />     <div className="contenttemple">
                      <Typography variant="h6" className=" ">
                        Baba Mandir
                      </Typography>
                      <Typography variant="p" className=" ">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </Typography>
                      </div>
                      </div>

                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className="templeimages">  <img src="/About/ganesh.jpg" />
                    <div className="contenttemple">  <Typography variant="h6" className=" ">
                        Ganpati Mandir     </Typography>
                        <Typography variant="p" className=" ">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </Typography>
                      </div>
                    </div>


                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className="templeimages">  <img src="/About/ganesh.jpg" />
                    <div className="contenttemple"><Typography variant="h6" className=" ">
                        Mahadev Mandir        </Typography>
                        <Typography variant="p" className=" ">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </Typography>
                      </div>
                    </div>


                  </Grid>

                </Grid>
              </Box>
            </TabPanel>
          </div>
        </Container>
      </Box>
      {<Footer />}
    </Box>
  );
};

export default About;
